import React, { Component } from 'react';
import Notification from  './notification';
class Confirmation  extends Component {
    
  state = { 
    notification:this.props.notification
};

    
    accept =() => {
      
      this.setState({notification:null});
    };
     decline =() =>{
      
      this.setState({notification:null});
    } ;
    
    render() {
      const{notification}= this.props;
      console.log(notification)
        console.log("rendering");
        return (
                <Notification notification={this.state.notification} onAccept={this.accept} onDecline={this.decline} />
             );
        
   }
  
  };

  export default Confirmation;