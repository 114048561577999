import React, { Component } from 'react';
import logo from './logo.svg';
import './App.css';
import Confirmation from './components/confirmation'

class App extends Component {
  render() {
    return (
      <div className="App">
       <Confirmation notification={{message:'Hello World1',type:'success'}}/>
       <Confirmation notification={{message:'Hello World2',type:'message'}}/>
       <Confirmation notification={{message:'Hello World3',type:'caution'}}/>
       <Confirmation notification={{message:'Hello World4',type:'error'}}/>
       <Confirmation notification={{message:'Hello World5'}}/>
       <Confirmation />
      </div>
    );
  }
}

export default App;
